import { useState } from 'react';
import IHistorico from '../../../interfaces/IHistorico';
import { removerAcentosECaracteresEspeciais } from './../../../helpers/strings';
import ModalDetalhesDoHistorico from './modalDetalhesDoHistorico';
import '../styles.scss';

interface HistoricoProps {
  historico: IHistorico,
  descricaoDaAcao: string,
  possuiDetalhes: boolean,
  icone: string
}

const Historico = ({ historico, icone, possuiDetalhes, descricaoDaAcao }: HistoricoProps) => {

  const [exibirDetalhes, alterarExibirDetalhes] = useState(false);
  const acaoSemCaracteres = removerAcentosECaracteresEspeciais(descricaoDaAcao);
  const tratarExibicaoDosDetalhes = () => alterarExibirDetalhes(true);
  const fecharExibicaoDosDetalhes = () => alterarExibirDetalhes(false);
  
  return (
    <>
      <div>
        <div className='grade__coluna colunaFlex'>
          <div className='centro'>
            <i className={icone} aria-hidden="true"></i>
            <div className={'linha-vertical historico'+acaoSemCaracteres}></div>
          </div>

          <div>
            <div className='grade__linha grade__linha_alinhar-vertical-a-base'>
              <label className='formulario__label'>
                <strong>{descricaoDaAcao} de inscrição:</strong> Saindo de '{historico.inscricaoOrigem}' e indo para '{historico.inscricaoDestino}'
              </label>
            </div>
            <div className='grade__linha grade__linha_alinhar-vertical-a-base'>
              <label className='formulario__label'>
                <strong>Data:</strong>  {historico.data.toString()}
              </label>
            </div>
            <div className='grade__linha grade__linha_alinhar-vertical-a-base'>
              <label className='formulario__label'>
                <strong>Responsável:</strong>  {historico.usuario}
              </label>
            </div>
            {possuiDetalhes &&
            <button type="button" onClick={() => tratarExibicaoDosDetalhes()} 
              className="botao botao_sem-preenchimento botao_medio botao_cor-atencao margemBotao">Visualizar detalhes</button>
            }
          </div>
        </div>
        <hr/>
      </div>
      {possuiDetalhes &&
      <ModalDetalhesDoHistorico 
        resumoDoHistorico={historico.resumoDoHistorico}
        deveExibirModal={exibirDetalhes} 
        fecharModal={fecharExibicaoDosDetalhes} />
      }
    </>
  );
};
export default Historico;
