import '../styles.scss';
import IResumoDoHistorico from '../../../interfaces/IResumoDoHistorico';

interface IDetalhesDoHistorico {
  deveExibirModal: Boolean;
  resumoDoHistorico: IResumoDoHistorico[];
  fecharModal: () => void;
}

const ModalDetalhesDoHistorico = ({resumoDoHistorico, deveExibirModal, fecharModal}: IDetalhesDoHistorico) => {
  return (
    <>
      <div role="dialog" aria-label="Diálogo" className={`dialogo dialogo_medio ${deveExibirModal ? 'dialogo_ativo' : ''}`}>
        <div className="dialogo__container">
          <div className="dialogo__cabecalho">
            <h2 className="dialogo__titulo">Detalhes da separação</h2>
            <span className="fecharModal"  onClick={() => fecharModal()}>&times;</span>
            <p className="dialogo__subtitulo"></p>
          </div>
          <div className="dialogo__corpo semBarraDeRolagem">
            {resumoDoHistorico?.length ?
              resumoDoHistorico.map((resumo: IResumoDoHistorico) =>
                <div>
                  <div className='grade__linha grade__linha_alinhar-vertical-a-base'>
                    <label className='formulario__label'>
                      <strong>{resumo.nomeDoPasso}</strong>  
                    </label>
                  </div>
                  <div className='grade__coluna colunaFlex grade__coluna_13'>
                    <div className='centro'>
                      <div className='linha-vertical endereco'></div>
                    </div>
                    <div>
                      <div className='grade__linha grade__linha_alinhar-vertical-a-base'>
                        <label className='formulario__label'>
                          {resumo.mensagem}
                        </label>
                      </div>
                    </div>
                  </div>
                  <hr/>
                </div>
              ):
              <div className="estado-vazio estado-vazio_altura-pequena estado-vazio_largura-pequena">
                <i className="far fa-align-slash estado-vazio__icone" aria-hidden="true"></i>
                <h2 className="estado-vazio__titulo">Nenhum detalhe encontrado</h2>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  );
};
export default ModalDetalhesDoHistorico;
